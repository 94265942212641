function isScrollbarExist() {
  var docHeight = $(document).height();
  var scroll = $(window).height() + $(window).scrollTop();
  return docHeight !== scroll;
}

function getScrollbarWidth() {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = 'scroll';

  // add inner div
  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return isScrollbarExist() ? widthNoScroll - widthWithScroll : 0;
}

function preloader() {
  const preloader = $('#preloader');

  $('body').css('overflow', 'hidden');
  $(window).on('load', () => {
    setTimeout(() => {
      preloader.fadeOut(400, function () {
        $(window).trigger('preloaderRemoved');
      });
      $('body').css('overflow', '');

      preloader.addClass('loaded').find('.preloader-wrapper')
        .css('margin-left', getScrollbarWidth())
    }, 200); 
  });
}
preloader();


$(document).ready(() => {
  svg4everybody();
  headerSearch();
  sortDrop();
  slider();
  tabs();
  headDrop();

  function headerSearch() {
    let btn = $('.js-search-btn'),
        drop = $('.js-header-search');

    btn.on('click', function() {
      $(this).toggleClass('active');
      drop.toggleClass('active');
    })
  }

  function sortDrop() {
    let btn = $('.js-sort-btn');

    btn.on('click', function() {
      let drop = $(this).parent().find('.js-sort-drop');

      $(this).toggleClass('active');
      drop.toggleClass('active');
    })

    $('body').on('click', function (e) {
      if (!$(e.target).closest('.head__sort').length) {
        btn.removeClass('active');
        $('.js-sort-drop').removeClass('active');
      }
    });
  }

  function slider() {
    new Swiper('.swiper-container', {
      spaceBetween: 0,
      freeMode: true,
      slidesPerView: 'auto'
    });
  }

  function tabs() {
    var self = $('.js-tabs'),
        tab = self.find('.js-tab-link'),
        content = self.find('.js-tab-content');
    tab.on('click', function (e) {
      e.preventDefault();
      var self = $(this),
          href = self.attr('href');
      tab.removeClass('active');
      self.addClass('active');
      content.removeClass('active').hide();
      $(href).addClass('active').fadeIn(300);
    });
  }

  function headDrop() {
    let btn = $('.js-head-btn'),
        drop = $('.js-head-drop'),
        link = $('.js-head-link');

    btn.on('click', function() {
      $(this).toggleClass('active');
      drop.toggleClass('active');
    })

    link.on('click', function(e) {
      e.preventDefault();
      link.removeClass('active');
      $(this).addClass('active');
      btn.text($(this).text()).removeClass('active');
      drop.removeClass('active');
    })

    $('body').on('click', function (e) {
      if (!$(e.target).closest('.head__drop ').length) {
        btn.removeClass('active');
        drop.removeClass('active');
      }
    });
  }
});
